<template lang="html">
    <div class="container-fluid p-5">
        <div class="row">
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                    <div class="card-body">
                        <h5 class="card-title">Pending Orders</h5>
                        <p class="card-text">{{dashboard.pending}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                    <div class="card-body">
                        <h5 class="card-title">Confirm Orders</h5>
                        <p class="card-text">{{dashboard.confirm}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                    <div class="card-body">
                        <h5 class="card-title">Reject Orders</h5>
                        <p class="card-text">{{dashboard.reject}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                    <div class="card-body">
                        <h5 class="card-title">Completed Orders</h5>
                        <p class="card-text">{{dashboard.completed}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                    <div class="card-body">
                        <h5 class="card-title">Merchant</h5>
                        <p class="card-text">{{dashboard.merchant}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                    <div class="card-body">
                        <h5 class="card-title">Member</h5>
                        <p class="card-text">{{dashboard.member}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                    <div class="card-body">
                        <h5 class="card-title">Shop</h5>
                        <p class="card-text">{{dashboard.shop}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                    <div class="card-body">
                        <h5 class="card-title">Items</h5>
                        <p class="card-text">{{dashboard.product}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-md-12">
                <h5>Data Analysis</h5>
                <hr>
            </div>
            <div class="col-md-3" v-for="schedule in dashboard['schedule']">
                <div class="card" style="width: 100%;">
                    <div class="card-body">
                        <h5 class="card-title">{{schedule.name}}</h5>
                        <p class="card-text">
                            <button type="button" class="btn btn-success">
                                Order <span class="badge badge-light">{{schedule.transporter_order.length}}</span>
                            </button>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <AreaChart/>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css'
   import AreaChart from "../components/AreaChart.vue";
import PieChart from "../components/PieChart.vue";
import BarChart from "../components/BarChart.vue";
import RadarChart from "../components/RadarChart.vue";
import LineChart from "../components/LineChart.vue"
    export default {
        name: 'dashboard',
        metaInfo: {
            title: "Home",
            titleTemplate: "%s ← HoHoDiDi Dashboard",
        },
        components: {
            Loading,
               AreaChart,
    PieChart,
    BarChart,
    RadarChart,
    LineChart
        },
        data() {
            return {
                isLoading: false,
                fullPage: true,
                dashboard: [],
                chartData: {
                    Books: 24,
                    Magazine: 30,
                    Newspapers: 10
                }
            }
        },
        computed: {
            ...mapState({})
        },
        methods: {
            ...mapActions({
                dashboardAction: 'dashboardAction',
            }),
            async dashboardDetail() {
                this.isLoading = true;
                await this.dashboardAction().then(res => {
                    if (res.status == "success") {
                        this.dashboard = res.data
                        this.isLoading = false
                    }
                }).catch(err => this.isLoading = true)
            },
        },
        async mounted() {
            this.isLoading = true
            this.dashboardDetail()
        }
    }
</script>


<style scoped lang="scss">
</style>